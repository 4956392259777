import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

export default function ContentInput(props) {
    const { title, content, updateTitle, updateContent, handleAdd, handleClose } = props;
    const textRef = useRef(null);
    const [showcontent, setShowcontent] = useState(false);
    const [localcontent, setLocalcontent] = useState(false);
    const [localcontenthtml, setLocalcontenthtml] = useState(false);
    const editableRef = useRef(null);
    const [value, setValue] = React.useState(content);

    useEffect(() => {
        updateContent(value)
    }, [value]);

    useEffect(() => {
        setValue(value)
    }, [content]);

    console.log("updated content");

    useEffect(() => {
        // textRef.current.style.height = "0px";
        // const scrollHeight = textRef.current.scrollHeight;
        // textRef.current.style.height = scrollHeight + "px";

        var tempcontent = content.replaceAll("\n","<br />");
        
        setLocalcontent(tempcontent);
        
        setLocalcontenthtml(tempcontent);
    }, [content]);

    useEffect(() => {
        if(localcontent){
            
            // alert(tempcontent);
            // setLocalcontent(tempcontent);
        }
        
        // setLocalcontent(tempcontent);
    }, [localcontent]);

    const onclickeventhandler = () =>{
        alert('sdf');
    }

    const textchangeevent = ()=>{
        alert("Sdf");
    }

    const inputTextChange = (e)=>{
        // console.log(editableRef.current.innerHTML);
        setLocalcontent(editableRef.current.innerHTML);
    }

    const inputBlurChange = (e)=>{
        console.log(localcontent);
        var tempcontent = localcontent;
        updateContent(localcontent);
    }

    function createMarkup() {
        var tempcontent = '';
        
        if(localcontenthtml){
            // console.log(localcontenthtml);
            tempcontent = localcontenthtml.replaceAll("\n","<br />");
        }
      // console.log(tempcontent);
      return {__html: tempcontent};
    }



    return (
        <div className="content-container">
            <div className="title-wrapper">
                <span className="noselect">##</span> <input
                    placeholder="Type Title Here..."
                    value={title} onChange={evt => updateTitle(evt.target.value)} />
                <div className="action-container-right">
                    <div className="action-button" onClick={handleClose}>
                        <CloseOutlined />
                    </div>
                </div>
            </div>
            <div className="content-wrapper">
                <MDEditor
                    value={content}
                    onChange={updateContent}
                    preview={'edit'}
                    previewOptions={{
                      rehypePlugins: [[rehypeSanitize]],
                    }}
                  />
                
                {showcontent &&
                <textarea
                    placeholder="Type Content Here..."
                    value={content}
                    rows={1}
                    ref={textRef}
                    onChange={evt => updateContent(evt.target.value)}
                    
                />
               }
                <div className="action-container-left">
                    <div className="action-button" onClick={handleAdd}>
                        <PlusOutlined />
                    </div>
                </div>
            </div>
        </div>
    )
}
