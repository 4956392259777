import React, { useState, useEffect } from 'react';
import './style.scss';
import { Button, Tree, Modal, Input, Spin } from 'antd';
import EditableTree from 'editable-tree-antd';
import { CarryOutOutlined, FormOutlined } from '@ant-design/icons';
import { useAppState } from "../../context/app.context";
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { withFirebase } from '../../components/firebase';

const MODAL_TYPE_CREATE = 'MODAL_TYPE_CREATE';
const MODAL_TYPE_REMOVE = 'MODAL_TYPE_REMOVE';
const MODAL_TYPE_NONE = 'MODAL_TYPE_NONE';

const MainMenu = (props) => {
  const { user, projects, settings, deleteProject, updateSettings, theme, setTheme, newModal, updateNewModal } = useAppState();
  const { selectedProjectId } = settings;
  const [openNewFlag, setOpenNewFlag] = useState(false);
  const [modalType, setModalType] = useState(MODAL_TYPE_NONE);
  const [title, setTitle] = useState('');
  const [titleValidMsg, setTitleValidMsg] = useState('');
  const [loading, setLoading] = useState(false);
  console.log("props", props);

  const projectList = [
    {
      title: 'Projects',
      key: 'project-list',
      children: [],
    },
  ];

  projects.sort(function (a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  for (const item of projects) {
    projectList[0].children.push({
      title: item.title,
      key: item.key,
      icon: <CarryOutOutlined />,
      createdAt: item.createdAt
    });
  }



  console.log("project list", projects);

  useEffect(() => {
    if (newModal) {

      setOpenNewFlag(true);
      setModalType(MODAL_TYPE_CREATE);
      setTitle('');
    }
  }, [newModal])

  function handleModalOk() {
    updateNewModal(false);
    switch (modalType) {
      case MODAL_TYPE_CREATE:
        handleCreate();
        break;
      case MODAL_TYPE_REMOVE:
        handleRemoveProject();
        break;
      default:
        break;
    }
  }

  const handleCreate = async () => {
    const { firebase } = props;
    if (title && title.length > 0) {
      console.log("create new", user);
      try {
        let param = {
          title: title,
          composeData: [{
            key: uuid(),
            title: 'Untitled_1',
            color: '#ffffff',
            data: [],
          }],
          libraryData: [],
          userId: user.email,
          createdAt: moment().format(),
          uid: user.uid
        }
        const postItemRef = firebase.db.ref('projects');
        postItemRef.push(param).then(projectItem => {
          settings.selectedProjectId = projectItem.ref.key;
          settings.selectedChipId = param.composeData[0].key;
          updateSettings({ ...settings });
        });
        setOpenNewFlag(false);
        setModalType(MODAL_TYPE_NONE);
      } catch (error) {
        console.log("any error found", error)
        setLoading(false);
      }
    } else {
      setTitleValidMsg('Title is required!');
    }
  };

  const handleSelectProject = (projectId) => {
    try {
      window.history.pushState({}, '', '/' + projectId + '/');
    } catch (e) {

    }

    props.setCurrentproject(projectId);


    let selectedProject = projects.find(
      (project) => project.key === projectId[0],
    );
    if (selectedProject) {
      console.log("cardKeynew Proj", projectId[0], selectedProject.key);
      settings.selectedProjectId = projectId[0];
      settings.selectedChipId = "";
      updateSettings({ ...settings });
      handleSetMain();
    }
  };

  const handleOpenNew = () => {
    setOpenNewFlag(true);
    setModalType(MODAL_TYPE_CREATE);
    setTitle('');

    handleSetMain();
  };

  function handleSetMain() {
    const { handleChangeLayout } = props;
    handleChangeLayout('id-row-main', { weight: 100 });
  }

  const handleChangeTitle = (evt) => {
    setTitle(evt.target.value);
    setTitleValidMsg("");
  };

  const handleCancel = () => {
    console.log("handle cancel")
    setOpenNewFlag(false);
    setModalType(MODAL_TYPE_NONE);
    updateNewModal(false);
  };

  async function handleRemoveProject() {
    setModalType(MODAL_TYPE_NONE);
    setOpenNewFlag(false);
    settings.selectedProjectId = "";
    settings.selectedChipId = "";
    updateSettings({ ...settings });
    deleteProject(selectedProjectId);
  }

  function handleRemoveModal() {
    setModalType(MODAL_TYPE_REMOVE);
    setOpenNewFlag(true);
  }

  function handleThemeUpdate() {
    if (theme === 'light') {
      document.documentElement.className = 'theme-dark';
      setTheme('dark');
    } else {
      setTheme('light');
      document.documentElement.className = 'theme-light';
    }
  }

  const renderButtons = () => (
    <div className="btn-group">
      <Button type="primary" onClick={handleOpenNew}>
        Create Project
      </Button>
      <Button type="danger" onClick={handleRemoveModal} disabled={selectedProjectId ? false : true}>
        Remove Project
      </Button>
      <Button type="success" onClick={handleThemeUpdate}>
        Update Theme
      </Button>
    </div>
  );

  const handleTextEnter = (evt) => {

    if (evt.key === 'Enter') {
      updateNewModal(false);
      handleCreate();
    }
  };

  let defaultSelectedKeys = selectedProjectId ? [selectedProjectId] : props.currentproject ? [props.currentproject] : [];

  useEffect(() => {
    if (projects.length > 0) {

      if (props.currentproject) {
        console.log("use efect called", props.currentproject);
        handleSelectProject([props.currentproject]);
      }
    }
  }, [projects])



  const modalTitle = modalType === MODAL_TYPE_CREATE ? 'Create a Project' : modalType === MODAL_TYPE_REMOVE ? 'Remove a Project' : '';

  return (
    <div className="main-menu">
      {renderButtons()}
      <div className={`project-tree-container theme-${theme}`}>
        <Tree
          className="client-tree"
          multiple={false}
          treeData={projectList}
          defaultExpandedKeys={['project-list']}
          selectedKeys={defaultSelectedKeys}
          showIcon={false}
          showLine={{ showLeafIcon: false }}
          onSelect={handleSelectProject}
        />
        <Modal
          title={modalTitle}
          visible={openNewFlag}
          onOk={handleModalOk}
          onCancel={handleCancel}
          className="create-project-modal"
        >
          {modalType === MODAL_TYPE_CREATE && <>
            <div className="title-container">
              <span>Title:</span>
              <Input value={title} onChange={handleChangeTitle} onKeyDown={handleTextEnter} />
              <div className="error-msg">{titleValidMsg}</div>
            </div>
          </>}
          {modalType === MODAL_TYPE_REMOVE && <div>
            <span>REMOVING THIS PROJECT WILL DELETE IT FOREVER. ARE YOU SURE YOU WANT TO DO THAT?</span>
          </div>}
        </Modal>
      </div>
      {loading && <div className="loading-container">
        <Spin size="large" />
      </div>}
    </div>
  );
};

export default withFirebase(MainMenu);
