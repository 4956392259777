import React, { useState } from 'react';
import './style.scss';
import { Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export default function FilterBar(props) {
  const [formVal, setFormVal] = useState(props.filter);

  function handleChange(key, value) {
    const updatedFormVal = {
      ...formVal,
      [key]: value,
    };
    setFormVal(updatedFormVal);

    const { updateFilter } = props;
    updateFilter(updatedFormVal);
  }

  return (
    <div className="filter-view-container">
      <div className="input-ele-container">
        <span>Search: </span>
        <Input
          value={formVal.search}
          onChange={(evt) => handleChange('search', evt.target.value)}
        />
      </div>
      <div className="action-close-container" onClick={props.handleClose}>
        <CloseOutlined />
      </div>
    </div>
  );
}
