import React, { useState, useEffect } from 'react';
import './App.css';
import LoginPage from './containers/login-page';
import RegisterPage from './containers/register-page';
import Firebase, { FirebaseContext } from './components/firebase';
import { Header } from './components';
import { PAGE_MAIN, PAGE_LOGIN, PAGE_REGISTER } from './assets/const/state';
import {AppStateProvider, useAppState} from "./context/app.context";
import MainApp from './containers/main-app';
import './theme.scss';
import axios from 'axios';

function App({firebase}) {
  const [pageMode, setPageMode] = useState(PAGE_LOGIN);
  const [leftMenu, setLeftMenu] = useState(false);
  const [rightMenu, setRightMenu] = useState(false);
  const [startFlag, setStartFlag] = useState(false);
  const [currentproject, setCurrentproject] = useState('');

  

  function handleLeftMenu(flag) {
      setStartFlag(true);
      setLeftMenu(flag)
  }

  return (
      <FirebaseContext.Provider value={firebase}>
          <AppStateProvider firebase={firebase}>
            <div className="App">
            
              {pageMode === PAGE_MAIN && <div>
                <Header leftMenu={leftMenu} rightMenu={rightMenu} handleLeftMenu={handleLeftMenu} handleRightMenu={setRightMenu} />
                <MainApp setLeftMenu={setLeftMenu} setCurrentproject={setCurrentproject} currentproject={currentproject} leftMenu={leftMenu} rightMenu={rightMenu} startFlag={startFlag} setStartFlag={setStartFlag} />
              </div>}
              {pageMode === PAGE_LOGIN && <LoginPage updatePageMode={setPageMode} />}
              {pageMode === PAGE_REGISTER && <RegisterPage updatePageMode={setPageMode} />}
            </div>
          </AppStateProvider>
      </FirebaseContext.Provider>
  );
}

export default App;
