import React from "react";
import "./style.scss";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

export default function TitleInput(props) {
  const { text, updateText, handleCloseCard, copyContent } = props;

  return (
    <div>
    <div style={{textAlign:"right"}}>
        <button className="btn-signin" onClick={copyContent}>Copy Story</button>
      </div>
    <div className="title-container noselect">
      
      <span>#</span> <input
        className="header-title"
        placeholder="Type Title Here..."
        value={text}
        onChange={(evt) => updateText(evt.target.value)}
      />

      <div className="action-container-right">
        <div className="action-button" onClick={handleCloseCard}>
          <CloseOutlined />
        </div>
      </div>
    </div>
    </div>
  );
}
