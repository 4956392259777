import React, { useState } from 'react';
import { colorOption } from '../../assets/const/colors';
import './style.scss';

const ColorPicker = ({ handleSetColor }) => {
  const [currentColor, setCurrentColor] = useState(null);

  const setColor = (color) => {
    setCurrentColor(color);
    handleSetColor(color);
  };

  return (
    <div className="color-picker-container">
      {colorOption.map((option, index) => {
        let backgroundStyle =
          currentColor === option.color
            ? {
                backgroundColor: option.color,
                border: '2px solid grey',
              }
            : {
                backgroundColor: option.color,
              };
        return (
          <div
            key={'color-picker-item' + index}
            className="color-picker-item"
            style={backgroundStyle}
            onClick={() => setColor(option.color)}
          ></div>
        );
      })}
    </div>
  );
};

export default ColorPicker;
