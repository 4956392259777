import React, { useState, useEffect } from 'react';
import './style.scss';
import { EditOutlined, CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { v4 as uuid } from 'uuid';
import TitleInput from './title-input';
import ContentInput from './content-input';
import { useAppState } from '../../../context/app.context';
let marked = require("marked");

const TAB_COMPOSE = 'TAB_COMPOSE';
const TAB_LIBRARY = 'TAB_LIBRARY';

export default function TabArea(props) {
    const { dataArr, tab } = props;
    const { projects, settings, updateProject } = useAppState();

    function handleChangeTitle(text, index) {
        const selectedProjectIndex = projects.findIndex(project => project.key === settings.selectedProjectId);
        if (tab === TAB_COMPOSE) {
            projects[selectedProjectIndex].composeData[index].title = text;
            updateProject(projects[selectedProjectIndex]);
        } else {
            projects[selectedProjectIndex].libraryData[index].title = text;
            updateProject(projects[selectedProjectIndex]);
        }
    }

    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    function copydata(row) {
        let compiletext = "# " + row.title + "\n";

        for (var i = 0; i < row.data.length; i++) {
            compiletext = compiletext + "## " + row.data[i].title + "\n" + row.data[i].content + "\n\n"
        }
        copyTextToClipboard(compiletext);
    }

    function handleCopyContent(index) {
        const selectedProjectIndex = projects.findIndex(project => project.key === settings.selectedProjectId);

        if (tab === TAB_COMPOSE) {
            let compose
            console.log("copy content", index, tab, projects, projects[selectedProjectIndex].composeData[index]);
            copydata(projects[selectedProjectIndex].composeData[index]);
            //projects[selectedProjectIndex].composeData[index].content = text;
            //updateProject(projects[selectedProjectIndex]);            
        } else {
            //projects[selectedProjectIndex].libraryData[index].title = text;
            //updateProject(projects[selectedProjectIndex]);
            copydata(projects[selectedProjectIndex].libraryData[index]);
        }
    }

    function handleChangeCard(text, key, dataIndex, cardIndex) {
        const selectedProjectIndex = projects.findIndex(project => project.key === settings.selectedProjectId);

        if (tab === TAB_COMPOSE) {
            const designPanelData =
                projects[selectedProjectIndex].composeData[dataIndex].data;

            let selectedItem = designPanelData[cardIndex];
            selectedItem[key] = text;
            designPanelData.splice(cardIndex, 1, selectedItem);
            updateProject({ ...projects[selectedProjectIndex] });

        } else {
            const designPanelData =
                projects[selectedProjectIndex].libraryData[dataIndex].data;
            let selectedItem = designPanelData[cardIndex];
            selectedItem[key] = text;
            designPanelData.splice(cardIndex, 1, selectedItem);
            updateProject({ ...projects[selectedProjectIndex] });
        }
    }

    function handleAdd(dataIndex, cardIndex) {
        const selectedProjectIndex = projects.findIndex(project => project.key === settings.selectedProjectId);

        const item = {
            title: `Untitled`,
            content: '',
            key: uuid(),
            color: '#ffffff',
            invisible: false,
        };

        if (tab === TAB_COMPOSE) {
            if (projects[selectedProjectIndex].composeData[dataIndex].data) {
                projects[selectedProjectIndex].composeData[dataIndex].data.splice(cardIndex + 1, 0, item);
            } else {
                projects[selectedProjectIndex].composeData[dataIndex].data = [item];
            }
        } else {
            if (projects[selectedProjectIndex].libraryData[dataIndex].data) {
                projects[selectedProjectIndex].libraryData[dataIndex].data.splice(cardIndex + 1, 0, item);
            } else {
                projects[selectedProjectIndex].libraryData[dataIndex].data = [item];
            }
        }
        updateProject(projects[selectedProjectIndex]);
    }

    function handleClose(dataIndex, cardIndex) {
        const selectedProjectIndex = projects.findIndex(project => project.key === settings.selectedProjectId);

        if (tab === TAB_COMPOSE) {
            projects[selectedProjectIndex].composeData[dataIndex].data.splice(cardIndex, 1);
        } else {
            projects[selectedProjectIndex].libraryData[dataIndex].data.splice(cardIndex, 1);
        }
        updateProject(projects[selectedProjectIndex]);
    }

    function handleAddCard(dataIndex) {
        const selectedProjectIndex = projects.findIndex(project => project.key === settings.selectedProjectId);
        const item = {
            title: `Untitled_`,
            key: uuid(),
            color: '#ffffff',
            data: [{
                title: `Untitled`,
                content: '',
                key: uuid(),
                color: '#ffffff',
                invisible: false,
            }],
        };

        if (tab === TAB_COMPOSE) {
            if (projects[selectedProjectIndex].composeData) {
                projects[selectedProjectIndex].composeData.splice(dataIndex + 1, 0, item);
            } else {
                projects[selectedProjectIndex].composeData = [item];
            }
        } else {
            if (projects[selectedProjectIndex].libraryData) {
                projects[selectedProjectIndex].libraryData.splice(dataIndex + 1, 0, item);
            } else {
                projects[selectedProjectIndex].libraryData = [item];
            }
        }
        updateProject(projects[selectedProjectIndex]);
    }

    function handleCloseCard(dataIndex) {
        const selectedProjectIndex = projects.findIndex(project => project.key === settings.selectedProjectId);

        if (tab === TAB_COMPOSE) {
            projects[selectedProjectIndex].composeData.splice(dataIndex, 1);
        } else {
            projects[selectedProjectIndex].libraryData.splice(dataIndex, 1);
        }
        updateProject(projects[selectedProjectIndex]);
    }

    return (
        <div className="data-area-container">
            {dataArr && dataArr.length > 0 && dataArr.map((dataItem, dataIndex) => {
                const classNameContainer = dataItem.data?.length > 0 ? "data-item-container" : "data-item-container normal-container"
                return (
                    <div className={classNameContainer}>
                        <TitleInput
                            text={dataItem.title}
                            copyContent={() => handleCopyContent(dataIndex)}
                            updateText={text => handleChangeTitle(text, dataIndex)}
                            handleAddCard={() => handleAddCard(dataIndex)}
                            handleCloseCard={() => handleCloseCard(dataIndex)}
                        />
                        {dataItem.data && dataItem.data.map((cardItem, cardIndex) => (
                            <ContentInput
                                tab={tab}
                                title={cardItem.title}
                                content={cardItem.content}
                                updateTitle={(text) => handleChangeCard(text, 'title', dataIndex, cardIndex)}
                                updateContent={(text) => handleChangeCard(text, 'content', dataIndex, cardIndex)}
                                handleAdd={() => handleAdd(dataIndex, cardIndex)}
                                handleClose={() => handleClose(dataIndex, cardIndex)}
                            />
                        ))}
                        {(!dataItem.data || dataItem.data.length === 0) && <div className="action-container-main">
                            <div className="action-button" onClick={() => handleAdd(dataIndex, 0)}>
                                <PlusOutlined />
                            </div>
                        </div>}
                        <div className="action-container">
                            <div className="add-section-btn" onClick={() => handleAddCard(dataIndex)}>
                                <PlusOutlined /> New Chip
                            </div>
                        </div>
                    </div>
                )
            })}
            {(!dataArr || dataArr.length === 0) && <div className="action-container">
                <div className="add-section-btn" onClick={() => handleAddCard(0)}>
                    <PlusOutlined /> New Chip
                </div>
            </div>}
        </div>
    )
}