import React, { useState, useEffect } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import axios from "axios/index";
import { themeList } from '../assets/const/theme';

const AppStateContext = React.createContext({
    projects: [],
    user: {},
    settings: {
        selectedProjectId: "",
        selectedChipId: ""
    }
})

const AppStateProvider = ({ firebase, children }) => {
    const [settings, updateSettings] = useState({});
    const [projects, updateProjects] = useState([]);
    const [user, updateUser] = useState({});
    const [theme, setTheme] = useState(themeList[0]);
    const [newModal, updateNewModal] = useState(false);

    useEffect(() => {
      setTheme('dark')
    }, []);

    useEffect(() => {
        if(user && user.email) {
            firebase.db.ref('projects').orderByChild("userId").equalTo(user.email).on('value', (snapshot) => {
                const projects = []
                snapshot.forEach((snap) => {
                    let project = snap.val();
                    project.key = snap.key;
                    projects.push(project);
                });
                updateProjects(JSON.parse(JSON.stringify(projects)));
            })
        }
    }, [user])

    function updateProject(project) {
    console.log("update",project);
        firebase.db.ref('projects/' + project.key).set(project);
        let projectIndex = projects.findIndex(item => item.key === project.key);
        projects.splice(projectIndex, 1, project);
        updateProjects([...projects]);
    }

    function deleteProject(projectKey) {
        let projectIndex = projects.findIndex(item => item.key === projectKey);
        projects.splice(projectIndex, 1);
        updateProjects([...projects]);
        firebase.db.ref('projects/' + projectKey).set(null);
    }

    return (
        <AppStateContext.Provider
            value={{
                settings,
                updateSettings,
                projects,
                updateProject,
                deleteProject,
                user,
                updateUser,
                theme,
                setTheme,
                newModal,
                updateNewModal
            }}
        >
            {children}
        </AppStateContext.Provider>
    )
}

const useAppState = () => React.useContext(AppStateContext);

export { AppStateProvider, useAppState };