import React, { useState } from 'react';
import './style.scss';
import { withFirebase } from "../../components/firebase";
import axios from 'axios';
import { PAGE_MAIN } from "../../assets/const/state";

const RegisterPage = (props) => {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');

    function handleRegister() {
        const { firebase, updatePageMode } = props;
        if (
            (email.length > 0 && passwordOne.length > 0 && passwordOne == passwordTwo)
        ) {
            firebase
                .doCreateUserWithEmailAndPassword(email, passwordOne)
                .then(async (result) => {
                    updatePageMode(PAGE_MAIN)
                })
                .catch((error) => {
                    console.log('register error', error);
                });
        }
    }

    function handleLogin() {
        const { updatePageMode } = props;
        updatePageMode('PAGE_LOGIN')
    }

    return (
        <div className="register-page">
            <div className="register-page-container">
                <div className="register-form">
                    <span>Story Chips Register</span>
                    <input className="input-firstname" placeholder="First Name" value={firstname} onChange={(evt) => setFirstname(evt.target.value)} />
                    <input className="input-lastname" placeholder="Last Name"  value={lastname} onChange={(evt) => setLastname(evt.target.value)} />
                    <input className="input-username" placeholder="User Name"  value={username} onChange={(evt) => setUsername(evt.target.value)} />
                    <input className="input-email" placeholder="Email"  value={email} onChange={(evt) => setEmail(evt.target.value)} />
                    <input className="input-password" placeholder="Password One" type="password"  value={passwordOne} onChange={(evt) => setPasswordOne(evt.target.value)} />
                    <input className="input-password" placeholder="Password Two" type="password" value={passwordTwo} onChange={(evt) => setPasswordTwo(evt.target.value)} />
                </div>
                <div className="btn-group-container">
                    <button className="btn-signup" onClick={handleRegister}>
                        Register
                    </button>
                    <button className="btn-signin" onClick={handleLogin}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}

export default withFirebase(RegisterPage);