import React, { useRef, useEffect } from 'react';
import { useGrid, useRefresh, useDrag } from 'muuri-react';
import './style.scss';
let timerOne;
let timerTwo;
let timerClick;
let clickEventFlag = false;
const ChipCard = React.memo(
  ({
    selectedItemKey,
    cardKey,
    detailItemKey,
    color,
    title,
    source,
    handleEdit,
    handleDetail,
  }) => {
    const actionRef = useRef(null);
    const { grid, id } = useGrid();
    const isDragging = useDrag();

    useEffect(() => {
      setTimeout(() => {
        if (actionRef?.current && cardKey === detailItemKey) {
          const position = actionRef.current.getBoundingClientRect();
          handleDetail(cardKey, title, position.left, position.top - 180);
        }
      }, 500);
    }, []);
    let className =
      selectedItemKey === cardKey
        ? `${id}-item chip-card selected`
        : `${id}-item chip-card`;

    function onClickHandler(event) {
      clearTimeout(timerOne);
      clearTimeout(timerTwo);

      if (event.detail === 1) {
        timerOne = setTimeout(handleClick, 500);
      } else if (event.detail === 2) {
        timerTwo = setTimeout(handleDoubleClick, 500);
      } else {
        handleThreeClick();
      }
    }

    function handleClick() {
    
      handleEdit(cardKey, false);
    }

    function handleDoubleClick() {
      handleEdit(cardKey, true);
    }

    function handleThreeClick() {}

    function onMouseUp(evt) {
      clearTimeout(timerClick);
      if (!clickEventFlag) {
        onClickHandler(evt);
      } else {
        clickEventFlag = false;
      }
    }

    function onMouseDown() {
      timerClick = setTimeout(() => (clickEventFlag = true), 300);
    }

    const barKey = cardKey.slice(0, 3).toUpperCase();
    const barTitle = `SC-${barKey}`;

    return (
      <div
        className={className}
        key={cardKey}
        onMouseUp={(evt) => onMouseUp(evt)}
        onMouseDown={(evt) => onMouseDown(evt)}
      >
        <div className="item-content" style={{ backgroundColor: color }}>
          <span className="item-title">{title}</span>
          <div
            ref={actionRef}
            className="action-container"
            onMouseDown={(evt) =>
              handleDetail(cardKey, title, evt.clientX, evt.clientY - 180)
            }
          ></div>
          {selectedItemKey === cardKey && source === 'compose' && (
            <div className="action-bar-container">{barTitle}</div>
          )}
        </div>
      </div>
    );
  },
);

export default ChipCard;
