import firebase from 'firebase';
import { prodConfig, devConfig } from '../../assets/const/const';

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
  constructor() {
    if (!firebase.apps || firebase.apps.length === 0) {
      firebase.initializeApp(config);
      this.db = firebase.database();
    }
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

    authListener = (callBackFunc) => {
      firebase.auth().onAuthStateChanged((user) => {
          callBackFunc(user)
      })
  }

  doSignOut = () => firebase.auth().signOut();

  doPasswordReset = (email) => firebase.auth().sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    firebase.auth().currentUser.updatePassword(password);

  signInWithPopup = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };
}

export default Firebase;
