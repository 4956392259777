import React, { forwardRef, useState } from 'react';
import './style.scss';

const Compose = forwardRef(
  ({ children, addChip, changeLayoutDown, handleSelect, count }, ref) => {
    const [showMenu, setShowMenu] = useState(false);
    const [pos, setPos] = useState({
      yPos: -1,
      xPos: -1,
    });

    const handleContextMenu = (evt) => {
      evt.preventDefault();
      setShowMenu(true);
      setPos({
        xPos: `${evt.pageX}px`,
        yPos: `${evt.pageY}px`,
      });
    };

    const handleClick = () => {
      setShowMenu(false);
    };

    function handleDoubleClick() {
      handleSelect('');
      changeLayoutDown();
    }

    let width = (count + 2) * 150 + 60;

    return (
      <div
        ref={ref}
        className="compose-panel"
        onContextMenu={handleContextMenu}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        
      >
        {children}
        {showMenu && (
          <ul
            className="context-menu-container"
            style={{
              top: pos.yPos,
              left: pos.xPos,
            }}
          >
            <li onClick={addChip}>Add a Chip</li>
          </ul>
        )}
      </div>
    );
  },
);

export default Compose;
