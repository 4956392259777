import React, { useState, useEffect } from 'react';
import './style.scss';
import { withFirebase } from '../../components/firebase';
import { PAGE_MAIN, PAGE_LOGIN, PAGE_REGISTER } from '../../assets/const/state';
import axios from 'axios';
import { useAppState } from '../../context/app.context';

const LoginPage = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { updateUser } = useAppState();

  useEffect(() => {
    const { firebase, updatePageMode } = props;
    firebase.authListener(async (user) => {
      console.log('user --- ', user);
      if (user) {
        updateUser(user?.providerData[0]);
        updatePageMode(PAGE_MAIN);
      } else {
        updatePageMode(PAGE_LOGIN);
      }
    });
  }, []);

  function handleSigninGoogle() {
    const { firebase, updatePageMode } = props;
    firebase
      .signInWithPopup()
      .then(async (result) => {
        updatePageMode(PAGE_MAIN);
      })
      .catch((error) => {
        console.log('auth login failed', error);
      });
  }

  const handleLogin = () => {
    const { firebase } = props;
    if (email.length > 0 && password.length > 0) {
      firebase
        .doSignInWithEmailAndPassword(email, password)
        .then((result) => {})
        .catch((error) => {
          console.log('sign in error', error);
        });
    }
  };

  const handleRegister = () => {
    const { updatePageMode } = props;
    updatePageMode('PAGE_REGISTER');
  };

  return (
    <div className="login-page">
      <div className="login-page-container">
        <div className="sign-in-form">
          <span>Story Chips Login</span>
          <input
            className="input-email"
            placeholder="email"
            value={email}
            onChange={(evt) => setEmail(evt.target.value)}
          />
          <input
            className="input-password"
            placeholder="password"
            value={password}
            type="password"
            onChange={(evt) => setPassword(evt.target.value)}
          />
        </div>
        <div className="btn-group-container">
          <button className="btn-signin" onClick={handleLogin}>
            Login
          </button>
          <button className="btn-signin-google" onClick={handleSigninGoogle}>
            Login with Google
          </button>
          <button className="btn-signup" onClick={handleRegister}>
            Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(LoginPage);
