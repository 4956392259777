import React, { useState, useEffect } from "react";
import "./style.scss";
import { Tabs, Radio } from "antd";
import { useAppState } from "../../context/app.context";
import TabArea from "./tab-area";
let marked = require("marked");
const { TabPane } = Tabs;

const TAB_COMPOSE = "TAB_COMPOSE";
const TAB_LIBRARY = "TAB_LIBRARY";



const SettingPanel = () => {
  const { projects, settings, theme } = useAppState();
  useEffect(() => { }, [projects, settings.selectedProjectId]);

  const selectedProject = projects.find(
    (project) => project.key === settings.selectedProjectId
  );

  if (!selectedProject || selectedProject.length === 0) {
    return (
      <div className={`empty-panel-container theme-${theme}`}>
        <h2>You need to select a project.</h2>
      </div>
    );
  }

  const projectTitle = selectedProject.title;

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }


  function copyContent() {
    var rows = selectedProject.composeData;

    let compiletext = "";

    for (var j = 0; j < rows.length; j++) {
      var row = rows[j];
      compiletext = compiletext + "# " + row.title + "\n";

      for (var i = 0; i < row.data.length; i++) {
        compiletext = compiletext + "## " + row.data[i].title + "\n" + row.data[i].content + "\n\n"
      }
    }


    copyTextToClipboard(compiletext);
  }

  return (
    <div className={`setting-panel theme-${theme}`}>
      <div className={`chiptitle`}>STORY</div>
      {/* <h1>{projectTitle}</h1> */}

      <div className={`tabbar`}>
        <div style={{ textAlign: "right" }}>
          <button className="btn-signin" onClick={copyContent} style={{ marginBottom: "10px" }}>Copy All Stories</button>
        </div>

        <Tabs defaultActiveKey={TAB_COMPOSE} type="card" size="default">
          <TabPane tab="PRIMARY" key={TAB_COMPOSE}>
            <TabArea
              tab={TAB_COMPOSE}
              dataArr={selectedProject ? selectedProject.composeData : []}
            />
          </TabPane>
          <TabPane tab="SECONDARY" key={TAB_LIBRARY}>
            <TabArea
              tab={TAB_LIBRARY}
              dataArr={selectedProject ? selectedProject.libraryData : []}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SettingPanel;
