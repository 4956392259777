import React, { forwardRef, useState } from 'react';
import './style.scss';

const Design = forwardRef(({ children, addChip, handleSelect }, ref) => {
  const [showMenu, setShowMenu] = useState(false);
  const [pos, setPos] = useState({
    yPos: -1,
    xPos: -1,
  });

  const handleContextMenu = (evt) => {
    evt.preventDefault();
    setShowMenu(true);
    setPos({
      xPos: `${evt.pageX}px`,
      yPos: `${evt.pageY}px`,
    });
  };

  const handleClick = () => {
    setShowMenu(false);
    handleSelect('');
  };

  return (
    <div
      ref={ref}
      className="design-panel"
      onContextMenu={handleContextMenu}
      onClick={handleClick}
    >
      {children}
      {showMenu && (
        <ul
          className="context-menu-container"
          style={{
            top: pos.yPos,
            left: pos.xPos,
          }}
        >
          <li onClick={addChip}>Add a Card</li>
        </ul>
      )}
    </div>
  );
});

export default Design;
