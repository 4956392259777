import React,{useState,useEffect} from 'react';
import { Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import './style.scss';
import { withFirebase } from '../firebase';
import moment from 'moment';
import { useAppState } from '../../context/app.context';
import { DownOutlined } from '@ant-design/icons';
import Avatar from '../avatar';

const MENU_KEY_COLOR_SETTINGS = 'MENU_KEY_COLOR_SETTINGS';
const MENU_KEY_DEFAULT_BEHAVIOUR = 'MENU_KEY_DEFAULT_BEHAVIOUR';
const MENU_KEY_UPGRADE_PREMIUM = 'MENU_KEY_UPGRADE_PREMIUM';
const MENU_KEY_LOGOUT = 'MENU_KEY_LOGOUT';
const MENU_KEY_THEME_LIGHT = 'MENU_KEY_THEME_LIGHT';
const MENU_KEY_THEME_DARK = 'MENU_KEY_THEME_DARK';

const Header = (props) => {
  const {
    user,
    projects,
    settings,
    updateSettings,
    updateUser,
    theme,
    setTheme,
  } = useAppState();

  useEffect(() => {
      //setTheme('dark')
    }, []);

  const [projecttitle, setProjecttitle] = useState('');

  function handleLogout() {
    updateSettings({
      selectedProjectId: '',
      selectedChipId: '',
    });
    updateUser({});
    const { firebase } = props;
    firebase.doSignOut((result) => {});
  }

  function handleColor() {}

  function handleDefault() {}

  function handleUpgrade() {}

  function handleMenuProject() {
    const { handleLeftMenu, leftMenu } = props;
    handleLeftMenu(!leftMenu);
  }

  function handleMenuExport() {
    const { handleRightMenu, rightMenu } = props;
    handleRightMenu(!rightMenu);
  }

  function menu() {
    return (
      <Menu>
        <Menu.Item key={MENU_KEY_COLOR_SETTINGS} onClick={handleColor}>
          Color Settings
        </Menu.Item>
        <Menu.Item
          key={MENU_KEY_THEME_LIGHT}
          icon={theme === 'light' ? <DownOutlined /> : null}
          onClick={() => setTheme('light')}
        >
          Light Theme
        </Menu.Item>
        <Menu.Item
          key={MENU_KEY_THEME_DARK}
          icon={theme === 'dark' ? <DownOutlined /> : null}
          onClick={() => setTheme('dark')}
        >
          Dark Theme
        </Menu.Item>
        <Menu.Item key={MENU_KEY_DEFAULT_BEHAVIOUR} onClick={handleDefault}>
          Default Behaviour
        </Menu.Item>
        <Menu.Item key={MENU_KEY_UPGRADE_PREMIUM} onClick={handleUpgrade}>
          Upgrade to Premium
        </Menu.Item>
        <Menu.Item key={MENU_KEY_LOGOUT} onClick={handleLogout}>
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  const currentDate = moment().format('MM-DD-YY');
  let title = "";

  const { selectedProjectId } = settings;
  if (projects) {

    const selectedProject = projects.find(
      (project) => project.key === selectedProjectId,
    );
    if (selectedProject) {
    console.log("selectedProject 123",selectedProject);
      title = `${selectedProject.title.toUpperCase()} ${moment(
        selectedProject.createdAt,
      ).format('MM-DD-YY')}`;

      
    }

  }

  return (
    <div className={`header-container theme-${theme}`}>
      <div>
      <a onClick={handleMenuProject}>PROJECTS</a>
      {title.trim() &&
        <span className="headerptitle">{title}</span>
      }
      </div>
      <span className="title">{'STORY CHIPS'}</span>
      <a onClick={handleMenuExport}>EXPORT</a>
      <div className="action-container">
        <Dropdown overlay={menu} trigger={['click']}>
          <a className="avatar-container" onClick={(e) => e.preventDefault()}>
            <Avatar url={user.photoURL} email={user.email} />
            <div className="avatar-wrapper-mobile">
              <MoreOutlined />
            </div>
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default withFirebase(Header);
