import React, { Component, useRef, useState, useEffect, useCallback } from 'react';
import Hotkeys from 'react-hot-keys';
import { getOptions } from '../../utils/utils';
import {
  Design,
  ChipCard,
  DesignCard,
  EditDesignCard,
  FilterBar,
  FilterBarSearch
} from '../../components';
import { MuuriComponent } from 'muuri-react';
import { v4 as uuid } from 'uuid';
import './style.scss';
import DesignCardUpdate from '../../components/design-card-update';
import { Tooltip } from 'antd';
import {
  DownCircleOutlined,
  InboxOutlined,
  PlusOutlined,
  PicCenterOutlined,
  PicLeftOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { useAppState } from '../../context/app.context';
import { isMobile } from 'react-device-detect';
import { useSingleAndDoubleClick } from '../../utils/hook'

let gridDesign;

const VIEW_MODE_RESIZE = 'VIEW_MODE_RESIZE';
const VIEW_MODE_SIZE_SMALL = 'VIEW_MODE_SIZE_SMALL';
const VIEW_MODE_SIZE_MEDIUM = 'VIEW_MODE_SIZE_MEDIUM';
const VIEW_MODE_SIZE_LARGE = 'VIEW_MODE_SIZE_LARGE';

const CARD_SIZE_SMALL = 'CARD_SIZE_SMALL';
const CARD_SIZE_MEDIUM = 'CARD_SIZE_MEDIUM';
const CARD_SIZE_LARGE = 'CARD_SIZE_LARGE';

const sizeMenuList = [
  VIEW_MODE_SIZE_SMALL,
  VIEW_MODE_SIZE_MEDIUM,
  VIEW_MODE_SIZE_LARGE,
];

const DesignPanel = (props) => {
  const { projects, settings, updateProject } = useAppState();
  const designRef = useRef();
  const [count, setCount] = useState(1);
  const [selectedItemKey, setSelectedItemKey] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [designOrder, setDesignOrder] = useState(null);
  const [viewModeIndex, setViewModeIndex] = useState(0);
  const [filterView, setFilterView] = useState(false);
  const [fullmode, setFullmode] = useState(false);
  const [panelFilter, setPanelFilter] = useState({
    search: ''
  });
  const [isTitleEditEnabled, setTitleEditEnabled] = useState(false);
  const [isCardTitleEditEnabled, setCardTitleEditEnabled] = useState(false);

  const selectedProject = projects.find(
    (project) => project.key === settings.selectedProjectId,
  );

  const [outsideclickcounter, setOutsideclickcounter] = useState(0);
  const handleDesignPanelClick = () => {
    // console.log("outsideclickcounter", outsideclickcounter);
    setOutsideclickcounter(outsideclickcounter + 1);
  }

  const selectedProjectIndex = projects.findIndex(
    (project) => project.key === settings.selectedProjectId,
  );

  let isCompose = true;
  let chipIndex = -1;

  const titlesingleclick = () => {

  }

  useEffect(() => {
    setCardTitleEditEnabled(false);
  }, [outsideclickcounter]);

  const titleDoubleClick = () => {
    setCardTitleEditEnabled(true);
  }

  const ontitleclick = useSingleAndDoubleClick(titlesingleclick, titleDoubleClick);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setCardTitleEditEnabled(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);


  if (selectedProject) {

    let composeIndex = selectedProject.composeData
      ? selectedProject.composeData.findIndex(
        function (composeItem) {
          return composeItem.key === settings.selectedChipId
        },
      )
      : -1;



    if (composeIndex >= 0) {
      isCompose = true;
      chipIndex = composeIndex;
    } else {
      isCompose = false;
      let libraryIndex = selectedProject.libraryData
        ? selectedProject.libraryData.findIndex(
          (libraryItem) => libraryItem.key === settings.selectedChipId,
        )
        : -1;
      if (libraryIndex >= 0) {
        chipIndex = libraryIndex;
      }
    }
  }

  useEffect(() => {
    setPanelFilter({
      search: ''
    });
  }, [chipIndex]);

  useEffect(() => {
    handleOrder();
  }, [designOrder]);



  function handleUpdateView() {
    if (viewModeIndex >= 2) {
      setViewModeIndex(0);
    } else {
      setViewModeIndex(viewModeIndex + 1);
    }
  }



  function handleUpdateFilter() { }

  function handlePassfilter(data) {
    if (panelFilter.search && panelFilter.search.length > 0) {
      return data.filter((item) => {
        if (JSON.stringify(item).toLowerCase().includes(panelFilter.search.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
    }

    return data;
  }

  function handleAddDesign() {

    const item = {
      title: `Untitled_${count}`,
      content: '',
      key: uuid(),
      color: '#ffffff',
      invisible: false,
    };
    const chipindex = projects[selectedProjectIndex].composeData.findIndex(
      (project) => project.key === selectedChip.key,
    );
    // console.log("selected chip new", projects[selectedProjectIndex].composeData[chipindex], count);
    // return;
    if (isCompose) {
      projects[selectedProjectIndex].composeData[chipindex].data.push(item);
    } else {
      projects[selectedProjectIndex].libraryData[chipindex].data.push(item);
    }
    updateProject(projects[selectedProjectIndex]);
    setCount(count + 1);
    setSelectedItemKey(item.key);
  }

  function handleItemUpdate(key, field, selectedItemKey) {

    if (isCompose) {
      const designPanelData =
        projects[selectedProjectIndex].composeData[chipIndex].data;
      const selectedItemIndex = designPanelData.findIndex(
        (item) => item.key === selectedItemKey,
      );

      console.log("isdirty123", isCompose, selectedProjectIndex, chipIndex, projects[selectedProjectIndex], designPanelData, selectedItemIndex, selectedItemIndex, selectedItemKey);

      //console.log("isdirty123",field,selectedItemIndex);
      if (selectedItemIndex > -1) {
        let selectedItem = designPanelData[selectedItemIndex];
        selectedItem[key] = field;

        designPanelData.splice(selectedItemIndex, 1, selectedItem);
        updateProject(projects[selectedProjectIndex]);
      }
    } else {
      const designPanelData =
        projects[selectedProjectIndex].libraryData[chipIndex].data;
      const selectedItemIndex = designPanelData.findIndex(
        (item) => item.key === selectedItemKey,
      );

      if (selectedItemIndex > -1) {
        let selectedItem = designPanelData[selectedItemIndex];
        selectedItem[key] = field;
        designPanelData.splice(selectedItemIndex, 1, selectedItem);
        updateProject(projects[selectedProjectIndex]);
      }
    }
  }

  function handleSelect(key) {



    if (key) {
      console.log("selected key change request", key);
      setSelectedItemKey(key);
      // setFullmode(false);
    }
  }

  function handleArchive(key) {
    const designPanelData = [];
    if (isCompose) {
      const designPanelData =
        projects[selectedProjectIndex].composeData[chipIndex].data;
      const selectedItemIndex = designPanelData.findIndex(
        (item) => item.key === key,
      );
      if (selectedItemIndex > -1) {
        designPanelData.splice(selectedItemIndex, 1);
        updateProject(projects[selectedProjectIndex]);
      }
    } else {
      const designPanelData =
        projects[selectedProjectIndex].libraryData[chipIndex].data;
      const selectedItemIndex = designPanelData.findIndex(
        (item) => item.key === key,
      );
      if (selectedItemIndex > -1) {
        designPanelData.splice(selectedItemIndex, 1);
        updateProject(projects[selectedProjectIndex]);
      }
    }
  }

  function handleUpdateOrder(orderKeys) {
    if (JSON.stringify(orderKeys) !== JSON.stringify(designOrder)) {
      setDesignOrder(orderKeys);
    }
  }

  function handleDesignMount(grid) {
    gridDesign = grid;
  }

  function handleDesignDragEnd() {
    const designItems = gridDesign.getItems();
    const orderKeys =
      designItems && designItems.length > 0
        ? designItems.map((designItem) => designItem.getKey())
        : null;
    handleUpdateOrder(orderKeys);
  }

  function handleOrder() {
    if (designOrder && designOrder.length > 0) {
      if (isCompose) {
        const designPanelData =
          projects[selectedProjectIndex].composeData[chipIndex].data;
        let designData = [];
        for (let key of designOrder) {
          let selectedItem = designPanelData.find((item) => item.key === key);
          if (selectedItem) {
            designData.push(selectedItem);
          }
        }
        projects[selectedProjectIndex].composeData[chipIndex].data = designData;
        updateProject(projects[selectedProjectIndex]);
      } else {
        const designPanelData =
          projects[selectedProjectIndex].libraryData[chipIndex].data;
        let designData = [];
        for (let key of designOrder) {
          let selectedItem = designPanelData.find((item) => item.key === key);
          if (selectedItem) {
            designData.push(selectedItem);
          }
        }
        projects[selectedProjectIndex].libraryData[chipIndex].data = designData;
        updateProject(projects[selectedProjectIndex]);
      }
    }
  }

  function handleBack() {
    setEditMode(false);
  }

  if (settings.selectedProjectId === '' || !settings.selectedProjectId) {
    return (
      <div className="empty-project-container">
        <InboxOutlined />
        <span className="label">Please select a project</span>
      </div>
    );
  }

  if (settings.selectedChipId === '' || !settings.selectedChipId) {
    return (
      <div className="empty-project-container">
        <InboxOutlined />
        <span className="label">Please select a chip</span>
      </div>
    );
  }
  console.log("cimpose data", selectedProject.composeData, chipIndex, isCompose, selectedProject.libraryData);
  let selectedChip = {
    data: []
  };



  if (chipIndex < 0) {

  } else {
    selectedChip = isCompose
      ? selectedProject.composeData[chipIndex]
      : selectedProject.libraryData[chipIndex];

    if (!selectedChip.data) {
      selectedChip.data = [];
    }
  }




  const designPanelData = handlePassfilter(selectedChip.data);

  console.log("isdirty", settings, selectedProject, selectedProjectIndex, chipIndex, designPanelData);

  const designRender = designPanelData.map((item) => (
    <DesignCardUpdate
      selectedItemKey={selectedItemKey}
      cardKey={item.key}
      key={item.key}
      color={item.color}
      invisible={item.invisible}
      viewMode={sizeMenuList[viewModeIndex]}
      handleSelect={handleSelect}
      handleItemUpdate={handleItemUpdate}
      handleArchive={handleArchive}
      leftMenu={props.leftMenu}
      rightMenu={props.rightMenu}
      fullmode={fullmode}
      setFullmode={setFullmode}
      isTitleEditEnabled={isTitleEditEnabled}
      setTitleEditEnabled={setTitleEditEnabled}
      outsideclickcounter={outsideclickcounter}
      {...item}
    />
  ));

  const selectedItem = designPanelData.find(
    (item) => item.key === selectedItemKey,
  );





  const handleInputChange = (e) => {
    setPanelFilter(e.target.value);
  }

  let backColor = selectedChip?.color ? selectedChip.color : '';
  const designPanelTitle = selectedChip?.title
    ? selectedChip.title.toUpperCase()
    : 'CREATIVE PRODUCTION';

  const handleDesignTitleChange = (evt) => {

    // setText(evt.target.value);
    // handleTitleChange(evt.target.value);
    let composeIndex = selectedProject.composeData.findIndex(
      (item) => item.key === selectedChip.key,
    );
    console.log("changed data", selectedChip.key, projects[selectedProjectIndex].composeData, composeIndex, projects[selectedProjectIndex].composeData[composeIndex]);
    if (composeIndex >= 0) {
      projects[selectedProjectIndex].composeData[composeIndex].title = evt.target.value;
      updateProject(projects[selectedProjectIndex]);
      return;
    } else {
      // let libraryIndex = selectedProject.libraryData.findIndex(
      //   (item) => item.key === selectedItemKey,
      // );
      // if (libraryIndex >= 0) {
      //   projects[selectedProjectIndex].libraryData[libraryIndex].title = evt.target.value;
      //   updateProject(projects[selectedProjectIndex]);
      // }
    }
    return;
  };

  const onKeyUp = (keyName, e, handle) => {
    // console.log("test:onKeyUp", keyName, e, handle);

    if (keyName == "option+n") {
      handleAddDesign();
    }

  }
  const onKeyDown = (keyName, e, handle) => {
    // console.log("test:onKeyDown", keyName, e, handle)

  }

  return (
    <Hotkeys
      keyName="option+n,alt+n"
      onKeyDown={onKeyDown.bind(this)}
      onKeyUp={onKeyUp.bind(this)}
    >
      <div
        className="design-panel-wrapper"
        style={{ backgroundColor: backColor }}
        onClick={handleDesignPanelClick}
      >
        <Design
          ref={designRef}
          addChip={handleAddDesign}
          handleSelect={handleSelect}
        >
          {filterView && (
            <FilterBar
              filter={panelFilter}
              updateFilter={setPanelFilter}
              chipIndex={chipIndex}
              handleClose={() => setFilterView(false)}
            />
          )}
          <div className="title-container" style={{ justifyContent: "center" }}>

            <span className="searchinput">
              Search
              <FilterBarSearch
                filter={panelFilter}
                updateFilter={setPanelFilter}
                chipIndex={chipIndex}
                handleClose={() => setFilterView(false)}
              />
            </span>
          </div>

          <div className="title-container">
            {!isCardTitleEditEnabled &&
              <span className="panel-title" onClick={ontitleclick}>{designPanelTitle}</span>
            }

            {isCardTitleEditEnabled &&
              <div className='titleeditmode'><input
                className="title-input"
                value={designPanelTitle}
                onClick={(e) => { e.preventDefault(); }}
                onChange={handleDesignTitleChange}
                autoFocus
              ></input></div>
            }


          </div>
          <MuuriComponent
            id={'design'}
            dragEnabled
            dragHandle=".content-header"
            onMount={handleDesignMount}
            onDragEnd={handleDesignDragEnd}
          >
            {designRender}
          </MuuriComponent>
          <div className="add-design-card-container" onClick={handleAddDesign}>
            <Tooltip title="Add Card">
              <PlusOutlined />
            </Tooltip>
          </div>
          {!isMobile &&
            <div
              className="update-view-design-card-container"
              onClick={handleUpdateView}
            >
              <Tooltip title="Resize">
                <PicCenterOutlined />
              </Tooltip>
            </div>
          }
        </Design>

        {editMode && (
          <EditDesignCard
            designItem={selectedItem}
            handleBack={handleBack}
            handleItemUpdate={handleItemUpdate}
          />
        )}
      </div>
    </Hotkeys>
  );
};

export default DesignPanel;
