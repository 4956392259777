const layoutOption = {
  global: {},
  layout: {
    type: 'row',
    weight: 100,
    children: [
      {
        type: 'row',
        weight: 100,
        id: "id-row-main",
        children: [
          {
            type: 'tabset',
            id: 'id-tabset-compose',
            weight: 35,
            selected: 0,
            tabStripHeight: -1,
            children: [
              {
                type: 'tab',
                name: 'Compose Panel',
                component: 'compose-panel',
              },
            ],
          },
          {
            type: 'tabset',
            id: 'id-tabset-design',
            weight: 65,
            selected: 0,
            tabStripHeight: -1,
            children: [
              {
                id: 'id-panel-design',
                type: 'tab',
                name: 'Design Panel',
                component: 'design-panel',
              },
            ],
          },
        ],
      }
    ],
  },
  borders: [
    {
      type: 'border',
      location: 'left',
      selected: 0,
      name: 'left',
      barSize: -1,
      children: [
        {
          type: 'tab',
          enableClose: false,
          name: '',
          component: 'project-menu',
        },
      ],
    },
    {
      type: 'border',
      location: 'right',
      name: 'right',
      selected: -1,
      barSize: -1,
      size: 400,
      children: [
        {
          type: 'tab',
          enableClose: false,
          name: '',
          component: 'setting-menu',
        },
      ],
    }
  ],
};

export { layoutOption };
