import React from 'react';
import './style.scss';
import { useAppState } from '../../context/app.context';

export default function EmptyPanel(props) {
  const { updateNewModal, theme } = useAppState();
  function handleClick() {
    const { setStartFlag } = props;
    updateNewModal(true);
    setStartFlag(true);
  }
  return (
    <div className={`empty-panel-container theme-${theme}`}>
      <h1>
        Select a project from the left hand menu or{' '}
        <a onClick={handleClick}>click here</a> to create a new one.
      </h1>
    </div>
  );
}
