import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Firebase from "./components/firebase";

// Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DNS,
//     integrations: [new Integrations.BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
// });

let firebase = new Firebase();

ReactDOM.render(
      <App firebase={firebase} />,
  document.getElementById('root'),
);

reportWebVitals();
