import React from 'react';
import './style.scss';

export default function Avatar(props) {
  if (props.url) {
    return (
      <div
        className="avatar-wrapper"
        style={{ backgroundImage: `url("${props.url}")` }}
      />
    );
  } else {
    const avatarName = props?.email?.slice(0, 2).toUpperCase();
    return <div className="avatar-wrapper-text">{avatarName}</div>;
  }
}
