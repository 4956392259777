import React, { Component } from 'react';
import MuuriGrid from 'react-muuri';
import { connect } from 'react-redux';
import PropertyPopup from '../../components/property-popup';
import './style.scss';

class LibraryPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      detailed: false,
      detail: {
        screenX: 0,
        screenY: 0,
      },
      library: props.library.library,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.compose) !== JSON.stringify(this.props.compose)
    ) {
      this.setState({ library: this.props.library.library });
    }
  }

  componentDidMount() {
    this.grid = new MuuriGrid({
      node: this.gridElement,
      defaultOptions: {
        dragEnabled: true,
      },
    });
    this.grid.getEvent('dragEnd');
  }

  componentWillUnmount() {
    this.grid.getMethod('destroy');
  }

  handleTitleChange = (title) => {
    let { library, selected } = this.state;
    let libraryIndex = library.findIndex((item) => item.id === selected);
    library[libraryIndex].title = title;
    this.setState({ library: [...library], title });
  };

  handleColorChange = (color) => {
    let { library, selected } = this.state;
    let libraryIndex = library.findIndex((item) => item.id === selected);
    library[libraryIndex].color = color;
    this.setState({ library: [...library] });
  };

  removeElement = () => {
    if (this.gridElement && this.gridElement.children.length) {
      this.grid.getMethod('remove', this.gridElement.children[0], {
        removeElements: true,
      });
    }
  };

  handleDetail = (id, title, evt) => {
    this.setState({
      title: title,
      detailed: true,
      detail: { screenX: evt.clientX, screenY: evt.clientY - 180 },
    });
  };

  handleSelect = (id, evt) => {
    this.setState({ selected: id });
  };

  handleBack = () => {
    this.setState({ detailed: false });
  };

  render() {
    const { library } = this.state;
    return (
      <div className="library-panel">
        <div className="library-panel-wrapper">
          <div ref={(gridElement) => (this.gridElement = gridElement)}>
            {library.map((item, index) => {
              let itemClass =
                item.id === this.state.selected ? 'item selected' : 'item';
              return (
                <div key={'library-item-' + index} className={itemClass}>
                  <div
                    className="item-content"
                    style={{ backgroundColor: item.color }}
                    onMouseDown={(evt) => this.handleSelect(item.id, evt)}
                  >
                    <span className="item-title">{item.title}</span>
                    <div
                      className="action-container"
                      onMouseDown={(evt) =>
                        this.handleDetail(item.id, item.title, evt)
                      }
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {this.state.detailed && (
          <PropertyPopup
            coordinate={{
              top: this.state.detail.screenY,
              left: this.state.detail.screenX,
            }}
            title={this.state.title}
            property={'VIEW_LIBRARY'}
            handleBack={this.handleBack}
            handleTitleChange={this.handleTitleChange}
            handleColorChange={this.handleColorChange}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    library: state.library,
  };
};

export default connect(mapStateToProps, null)(LibraryPanel);
