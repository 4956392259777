import React, { useState, useRef } from 'react';
import './style.scss';
import {
  BgColorsOutlined,
  FileImageOutlined,
  DeleteOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { ColorPicker } from '../../components';
import { v4 as uuid } from 'uuid';
import S3 from 'react-aws-s3';

const EditDesignCard = ({ designItem, handleBack, handleItemUpdate }) => {
  const [content, setContent] = useState(designItem.content);
  const [color, setColor] = useState(designItem.color);
  const [modalFlag, setModalFlag] = useState(false);
  const [image, setImage] = useState(designItem.image);
  const fileInput = useRef();

  const handleColorChange = (color) => {
    setColor(color);
    handleItemUpdate('color', color);
  };

  const handleUpload = () => {
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME /* optional */,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
      s3Url: process.env.REACT_APP_S3_URL,
    };
    let file = fileInput.current.files[0];
    let newFileName = uuid();
    if (file) {
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(file, newFileName).then((data) => {
        handleItemUpdate('image', data.location);
        setImage(data.location);
      });
    }
  };

  const handleClose = () => {
    handleBack('');
  };

  const handleContent = (content) => {
    setContent(content);
    handleItemUpdate('content', content);
  };

  return (
    <div className="edit-design-card-wrapper">
      <div className="card-back"></div>
      <div className="edit-design-container" style={{ backgroundColor: color }}>
        {image && <img className="image-wrapper" src={image} />}
        <div className="content-wrapper">
          <textarea
            className="content-container"
            value={content}
            onChange={(evt) => handleContent(evt.target.value)}
          />
        </div>
        <div className="toolbar-container">
          <div className="btn-group">
            <div className="color-action">
              <BgColorsOutlined />
              <div className="color-picker-wrapper">
                <ColorPicker handleSetColor={handleColorChange} />
              </div>
            </div>
            <div className="image-ation">
              <FileImageOutlined />
              <input
                type="file"
                ref={fileInput}
                className="image-uploader"
                onChange={handleUpload}
                accept="image/*"
              ></input>
            </div>
            <div className="archive-action">
              <DeleteOutlined />
            </div>
          </div>
          <div className="close-btn-wrapper">
            <CloseOutlined onClick={handleClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDesignCard;
