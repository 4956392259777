import React, { useState, useRef, useEffect, useCallback } from 'react';
import { MuuriComponent } from 'muuri-react';
import { getOptions } from '../../utils/utils';
import { Compose, Library, ChipCard } from '../../components';
import SplitPane from 'react-split-pane';
import { connect } from 'react-redux';
import './style.scss';
import PropertyPopup from '../../components/property-popup';
import { v4 as uuid } from 'uuid';
import { useBeforeunload } from 'react-beforeunload';
import { notification } from 'antd';
import { PlusOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import { useAppState } from '../../context/app.context';

let gridCompose;
let gridLibrary;

const ComposePanel = ({ handleChangeLayout, setCurrentproject, currentproject }) => {
  const {
    projects,
    updateProject,
    settings,
    updateSettings,
    theme,
  } = useAppState();
  const [selectedItemKey, setSelectedItemKey] = useState(
    settings.selectedChipId,
  );
  const [detailItemKey, setDetailItemKey] = useState('');
  const [detailed, setDetailed] = useState(false);
  const [count, setCount] = useState(1);
  const [detail, setDetail] = useState({
    screenX: 0,
    screenY: 0,
  });
  const [title, setTitle] = useState('');
  const [searchstr, setSearchstr] = useState('');
  const composeRef = useRef();
  const libraryRef = useRef();

  const [itemData, setItems] = useState({});

  const selectedProject = projects.find(
    (project) => project.key === settings.selectedProjectId,
  );
  console.log("cardkey", selectedProject);
  const selectedProjectIndex = projects.findIndex(
    (project) => project.key === settings.selectedProjectId,
  );
  function handleAddCompose() {
    setSearchstr('');
    const item = {
      title: `Untitled_${count}`,
      key: uuid(),
      color: '#ffffff',
      data: [],
    };

    if (projects[selectedProjectIndex].composeData) {
      projects[selectedProjectIndex].composeData.push(item);
    } else {
      projects[selectedProjectIndex].composeData = [item];
    }
    updateProject(projects[selectedProjectIndex]);
    setCount(count + 1);
    setDetailItemKey(item.key);
  }

  function handleAddLibrary() {
    const item = {
      title: `Untitled_${count}`,
      key: uuid(),
      color: '#ffffff',
      data: [],
    };
    if (projects[selectedProjectIndex].libraryData) {
      projects[selectedProjectIndex].libraryData.push(item);
    } else {
      projects[selectedProjectIndex].libraryData = [item];
    }
    updateProject(projects[selectedProjectIndex]);
    setCount(count + 1);
    setDetailItemKey(item.key);
  }

  function handleSelect(key) {
    setSelectedItemKey(key);
  }

  function handleEdit(cardKey, layoutUp) {
    let composeItemIndex = selectedProject.composeData
      ? selectedProject.composeData.findIndex((item) => item.key === cardKey)
      : -1;

    console.log("cardKeynew", cardKey, composeItemIndex, selectedProject.key);
    if (composeItemIndex >= 0) {
      settings.selectedChipId = cardKey;
      settings.selectedProjectId = selectedProject.key;
      updateSettings({ ...settings });
      setSelectedItemKey(cardKey);

      if (layoutUp) {
        changeLayoutUp();
      }
      return;
    }

    let libraryItemIndex = selectedProject.libraryData
      ? selectedProject.libraryData.findIndex((item) => item.key === cardKey)
      : -1;

    if (libraryItemIndex >= 0) {
      settings.selectedChipId = cardKey;
      settings.selectedProjectId = selectedProject.key;
      updateSettings({ ...settings });
      setSelectedItemKey(cardKey);

      if (layoutUp) {
        changeLayoutUp();
      }
      return;
    }
  }

  function handleTitleChange(title) {
    let composeIndex = selectedProject.composeData.findIndex(
      (item) => item.key === selectedItemKey,
    );
    if (composeIndex >= 0) {
      projects[selectedProjectIndex].composeData[composeIndex].title = title;
      updateProject(projects[selectedProjectIndex]);
      return;
    } else {
      let libraryIndex = selectedProject.libraryData.findIndex(
        (item) => item.key === selectedItemKey,
      );
      if (libraryIndex >= 0) {
        projects[selectedProjectIndex].libraryData[libraryIndex].title = title;
        updateProject(projects[selectedProjectIndex]);
      }
    }
    return;
  }

  function handleColorChange(color) {
    let composeIndex = selectedProject.composeData.findIndex(
      (item) => item.key === selectedItemKey,
    );
    if (composeIndex >= 0) {
      projects[selectedProjectIndex].composeData[composeIndex].color = color;
      updateProject(projects[selectedProjectIndex]);
      return;
    } else {
      let libraryIndex = selectedProject.libraryData.findIndex(
        (item) => item.key === selectedItemKey,
      );
      if (libraryIndex >= 0) {
        projects[selectedProjectIndex].libraryData[libraryIndex].color = color;
        updateProject(projects[selectedProjectIndex]);
      }
    }
    return;
  }

  function handleRemove() {
    let composeIndex = selectedProject.composeData.findIndex(
      (item) => item.key === selectedItemKey,
    );
    if (composeIndex >= 0) {
      projects[selectedProjectIndex].composeData.splice(composeIndex, 1);
      updateProject(projects[selectedProjectIndex]);
      settings.selectedChipId = '';
      updateSettings({ ...settings });
      setDetailed(false);
      return;
    } else {
      let libraryIndex = selectedProject.libraryData.findIndex(
        (item) => item.key === selectedItemKey,
      );
      if (libraryIndex >= 0) {
        projects[selectedProjectIndex].libraryData.splice(libraryIndex, 1);
        updateProject(projects[selectedProjectIndex]);
        settings.selectedChipId = '';
        updateSettings({ ...settings });
      }
    }
    setDetailed(false);
  }

  function handleMoveCards(option) {
    const { key, fromId, toId } = option;
    if (fromId === 'library') {
      let transferredItem = selectedProject.libraryData.find(
        (item) => item.key === key,
      );
      if (selectedProject.composeData) {
        selectedProject.composeData.push(transferredItem);
      } else {
        selectedProject.composeData = [transferredItem];
      }
      selectedProject.libraryData = selectedProject.libraryData.filter(
        (item) => item !== transferredItem,
      );
    } else {
      let transferredItem = selectedProject.composeData.find(
        (item) => item.key === key,
      );
      if (selectedProject.libraryData) {
        selectedProject.libraryData.push(transferredItem);
      } else {
        selectedProject.libraryData = [transferredItem];
      }
      selectedProject.composeData = selectedProject.composeData.filter(
        (item) => item !== transferredItem,
      );
    }
    updateProject(selectedProject);
  }

  function handleOrderCompose(orderKeys) {
    if (orderKeys && orderKeys.length > 0 && selectedProjectIndex) {
      const updateComposeData = [];
      for (let index = 0; index < orderKeys.length; index++) {
        const updateItem = projects[selectedProjectIndex].composeData.find(
          (item) => item.key === orderKeys[index],
        );
        if (updateItem) {
          updateComposeData.push(updateItem);
        }
      }
      projects[selectedProjectIndex].composeData = updateComposeData;
      updateProject(projects[selectedProjectIndex]);
    }
  }

  function handleOrderLibrary(orderKeys) {
    if (orderKeys && orderKeys.length > 0) {
      const updateLibraryData = [];
      for (let index = 0; index < orderKeys.length; index++) {
        const updateItem = projects[selectedProjectIndex].libraryData.find(
          (item) => item.key === orderKeys[index],
        );
        if (updateItem) {
          updateLibraryData.push(updateItem);
        }
      }
      projects[selectedProjectIndex].libraryData = updateLibraryData;
      updateProject(projects[selectedProjectIndex]);
    }
  }

  function handleDetail(id, title, screenX, screenY) {
    handleEdit(id, false);
    setTitle(title);
    setDetailed(true);
    setDetail({ screenX, screenY });
  }

  function handleBack() {
    setDetailed(false);
  }

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setDetailed(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    setSelectedItemKey(settings.selectedChipId);
  }, [settings.selectedChipId]);

  useEffect(() => {
    if (currentproject) {
      setSearchstr("");
    }
  }, [currentproject]);

  function changeLayoutUp() {
    handleChangeLayout('id-tabset-design', { weight: 78 });
    handleChangeLayout('id-tabset-compose', { weight: 22 });
  }

  function changeLayoutDown() {
    handleChangeLayout('id-tabset-design', { weight: 50 });
    handleChangeLayout('id-tabset-compose', { weight: 50 });
  }

  function handleComposeMount(grid) {
    gridCompose = grid;
  }

  function handleLibraryMount(grid) {
    gridLibrary = grid;
  }

  function handleComposeDragEnd() {
    try {
      const libraryItems = gridCompose.getItems();
      const orderKeys =
        libraryItems && libraryItems.length > 0
          ? libraryItems.map((composeItem) => composeItem.getKey())
          : null;
      handleOrderCompose(orderKeys);
    } catch (ex) {

    }

  }

  function handleLibraryDragEnd() {
    const libraryItems = gridLibrary.getItems();
    const orderKeys =
      libraryItems && libraryItems.length > 0
        ? libraryItems.map((composeItem) => composeItem.getKey())
        : null;
    handleOrderLibrary(orderKeys);
  }

  if (settings.selectedProjectId === '' || !settings.selectedProjectId) {
    return (
      <div className="empty-project-container">
        <InboxOutlined />
        <span className="label">Please select a project</span>
      </div>
    );
  }

  let filteredprojects = selectedProject.composeData.filter(function (item) {
    if (item.title.toLowerCase().includes(searchstr.toLowerCase()) && searchstr) {
      return true;
    } else if (!searchstr) {
      return true;
    } else {
      return false;
    }
  });

  const children = {
    compose: filteredprojects
      ? filteredprojects.map((item) => {
        return <ChipCard
          key={item.key}
          source="compose"
          selectedItemKey={selectedItemKey}
          detailItemKey={detailItemKey}
          cardKey={item.key}
          handleDetail={handleDetail}
          handleEdit={handleEdit}
          {...item}
        />
      })
      : [],
    library: selectedProject.libraryData
      ? selectedProject.libraryData.map((item) => (
        <ChipCard
          key={item.key}
          source="library"
          selectedItemKey={selectedItemKey}
          detailItemKey={detailItemKey}
          handleDetail={handleDetail}
          handleEdit={handleEdit}
          cardKey={item.key}
          {...item}
        />
      ))
      : [],
  };

  const handleInputChange = (e) => {
    setSearchstr(e.target.value);
  }

  const projectName = selectedProject.title.toUpperCase();
  const projectUpdatedDate = moment(selectedProject.updated).format('DD-MM-YY');

  const projectTitle = `${projectName} ${projectUpdatedDate}`;

  return (
    <div className={`compose-page theme-${theme}`}>
      <div className="compose-page-wrapper ">
        <div >
          <Compose
            ref={composeRef}
            addChip={handleAddCompose}
            changeLayoutDown={changeLayoutDown}
            handleSelect={handleSelect}
            count={selectedProject?.composeData?.length}
          >
            <div className="panel-title-container">
              <span className="panel-sub-title"><b>STORY</b></span>

              <span className="searchinput">
                Search
                <input
                  onChange={handleInputChange}
                  value={searchstr}
                />
              </span>

              <span className="panel-title"></span>


            </div>
            <div className="compose-panel-viewport">
              <div className="empty-item-container" onClick={handleAddCompose}>
                <PlusOutlined />
              </div>
              <MuuriComponent
                id={'compose'}
                onMount={handleComposeMount}
                onDragEnd={handleComposeDragEnd}
                onSend={handleMoveCards}
                {...getOptions(composeRef, libraryRef, 'move')}
              >
                {children.compose}
              </MuuriComponent>
            </div>
          </Compose>
        </div>
      </div>
      {detailed && (
        <PropertyPopup
          coordinate={{ top: detail.screenY, left: detail.screenX }}
          title={title}
          property={'VIEW_COMPOSE'}
          handleBack={handleBack}
          handleTitleChange={handleTitleChange}
          handleColorChange={handleColorChange}
          handleRemove={handleRemove}
          handleEdit={handleEdit}
        />
      )}
    </div>
  );
};

export default ComposePanel;
