import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useRefresh } from 'muuri-react';
import { ChromePicker } from 'react-color';
import { ResizableBox } from 'react-resizable';
import { debounce } from 'underscore';
import showdown from 'showdown';
import { Buffer } from "buffer";



import './style.scss';
import {
  BgColorsOutlined,
  FileImageOutlined,
  DeleteOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  SaveOutlined,
  EyeOutlined,
  DragOutlined,
} from '@ant-design/icons';
import { shadeColor } from '../../utils/utils';
import ColorPicker from '../color-picker';
import S3 from 'react-aws-s3';
import { v4 as uuid } from 'uuid';
import { Tooltip } from 'antd';
import SizePicker from '../size-picker';
import RichTextEditor from 'react-rte';
// import RichTextEditor from 'react-rte-image';
import { isMobile } from 'react-device-detect';
import { useSingleAndDoubleClick } from '../../utils/hook'
let marked = require('marked');



const urlValidator = /(https?:\/\/[^\s]+)/g;

const VIEW_MODE_SIZE_SMALL = 'VIEW_MODE_SIZE_SMALL';
const VIEW_MODE_SIZE_MEDIUM = 'VIEW_MODE_SIZE_MEDIUM';
const VIEW_MODE_SIZE_LARGE = 'VIEW_MODE_SIZE_LARGE';

const CARD_SIZE_SMALL = 'CARD_SIZE_SMALL';
const CARD_SIZE_MEDIUM = 'CARD_SIZE_MEDIUM';
const CARD_SIZE_LARGE = 'CARD_SIZE_LARGE';

const ResizableWrapper = (Component) => {
  return function WrappedComponent(props) {
    const ref = useRef();
    const refresh = useRefresh();

    const refreshWithdebounce = debounce(
      () => requestAnimationFrame(refresh),
      50,
    );


    const { viewMode, cardKey, handleSelect, selectedItemKey, fullmode, setTitleEditEnabled } = props;


    console.log("selected key changesss", selectedItemKey, cardKey, props);

    const editMode = selectedItemKey === cardKey;

    useEffect(() => {
      refreshWithdebounce();
    }, [viewMode]);



    useEffect(() => {
      refreshWithdebounce();
    }, [props.leftMenu, props.rightMenu]);

    function handleCardClick(evt) {
      evt.stopPropagation();
      if (!editMode) {

        handleSelect(cardKey);
      }

    }

    function handleSize() {
      let vmode = viewMode;
      if (isMobile) {
        vmode = VIEW_MODE_SIZE_LARGE;
      }

      //make default size to big when export section is opened
      if (props.rightMenu) {
        vmode = VIEW_MODE_SIZE_LARGE;
      }

      switch (vmode) {
        case VIEW_MODE_SIZE_SMALL:
          return {
            width: '30%',
            height: '330px',
          };
        case VIEW_MODE_SIZE_MEDIUM:
          return {
            width: '48%',
            height: '450px',
          };
        case VIEW_MODE_SIZE_LARGE:
          return {
            width: '96%',
            height: '450px',
          };
        default:
          return {
            width: '30%',
            height: '330px',
          };
      }
    }

    const cardStyle = handleSize();

    console.log("mode check", editMode, fullmode);

    return (
      <div
        ref={ref}
        className={(editMode && fullmode) ? 'design-card full-screen-mode' : 'design-card'}
        style={cardStyle}

      >
        <div className="muuri-item">
          <Component {...props} />
        </div>
      </div>
    );
  };
};

const DesignCardUpdate = ResizableWrapper(
  ({
    color,
    image,
    title,
    content,
    invisible,
    selectedItemKey,
    cardKey,
    size,
    handleSelect,
    handleItemUpdate,
    handleArchive,
    fullmode,
    setFullmode,
    isTitleEditEnabled,
    setTitleEditEnabled,
    outsideclickcounter
  }) => {
    const [text, setText] = useState(content);
    const [cardTitle, setCardTitle] = useState(title ? title : '');
    const [cardColor, setCardColor] = useState(color);
    const [cardImage, setCardImage] = useState(image);
    const fileInput = useRef();
    const editableRef = useRef(null);
    const [value, setValue] = useState(RichTextEditor.createEmptyValue());
    const [isdirty, setIsdirty] = useState(false);
    const rtEditor = useRef();
    const [localmode, setLocalmode] = useState(false);

    useEffect(() => {
      if (Buffer) {
        window.Buffer = window.Buffer || Buffer.Buffer;
      }
    }, [Buffer]);

    console.log("selected key change 1", cardTitle);




    const titlesingleclick = () => {
      // alert("single click");
    }

    const titleDoubleClick = () => {
      setLocalmode(true);
      setTitleEditEnabled(true);
    }

    const ontitleclick = useSingleAndDoubleClick(titlesingleclick, titleDoubleClick);

    const editMode = selectedItemKey === cardKey;
    console.log("editMode", editMode);
    if (!editMode) {
      //setFullmode(false);
    }

    const escFunction = useCallback((event) => {
      if (event.keyCode === 27) {
        handleSelect('');
        setTitleEditEnabled(false);
        setFullmode(false);
        setLocalmode(false);
      }
    }, []);

    useEffect(() => {
      document.addEventListener("keydown", escFunction);

      return () => {
        document.removeEventListener("keydown", escFunction);
      };
    }, [escFunction]);

    const disableedit = () => {
      console.log("disable");
      // handleSelect('');
      setTitleEditEnabled(false);
      setLocalmode(false);
    }

    useEffect(() => {
      document.querySelector(".flexlayout__tab_border_left").addEventListener("click", disableedit);
      document.querySelector(".compose-page").addEventListener("click", disableedit);

      // return () => {
      //   document.querySelector(".flexlayout__tab_border_left").removeEventListener("click", disableedit);
      // };
    }, [escFunction]);


    useEffect(() => {
      setText(content);
      console.log("user content", value.setContentFromString(content, 'markdown'));
      setValue(value.setContentFromString(content, 'markdown'));
    }, [content]);

    useEffect(() => {
      setCardTitle(title)
    }, [title])

    const handleTextChange = (evt) => {
      setText(evt.target.value);
      handleItemUpdate('content', evt.target.value, cardKey);
    };

    const handleTitleChange = (evt) => {
      setCardTitle(evt.target.value);
      handleItemUpdate('title', evt.target.value, cardKey);
    };

    const handleColorChange = (color) => {
      setCardColor(color);
      handleItemUpdate('color', color, cardKey);
    };



    const handleUpload = () => {
      console.log("yellow");
      const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        dirName: process.env.REACT_APP_DIR_NAME /* optional */,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
        s3Url: process.env.REACT_APP_S3_URL,
      };
      console.log("upload data", config);
      let file = fileInput.current.files[0];
      let newFileName = uuid();
      if (file) {
        const ReactS3Client = new S3(config);
        ReactS3Client.uploadFile(file, newFileName).then((data) => {
          console.log("upload data", data);

          //setText(updatedText);
          //handleItemUpdate('content', updatedText,cardKey);

          const location = process.env.REACT_APP_S3_URL + data.key

          const updatedText = value.toString('markdown') + ` ![](${location})`;

          setText(updatedText);
          handleItemUpdate('content', updatedText, cardKey);
        });
      }
    };

    let className =
      selectedItemKey === cardKey ? `card-content selected` : `card-content`;
    const headerColor = shadeColor(cardColor, -30);

    const emptyContent = !content || content.length === 0;
    let actionBtnColor = cardColor === '#ffffff' ? '#69696a' : '#ffffff';

    let contentArr = [];
    if (!fullmode) {
      contentArr = content.split(urlValidator);
    }

    if (!fullmode && isdirty) {
      console.log("update here");
      //console.log("isdirty","onsave",isdirty,value.toString('markdown'));
      setText(value.toString('markdown'));
      handleItemUpdate('content', value.toString('markdown'), cardKey);
      setIsdirty(false)


    }

    const inputBlurChange = (e) => {
      let input = editableRef.current.innerHTML

      setText(input);
      handleItemUpdate('content', input, cardKey);
    }

    function createMarkup() {
      let converter = new showdown.Converter()
      //console.log(converter.makeHtml(text));
      //console.log("markup");
      let _htm = converter.makeHtml(text.replace("//", "/"));
      console.log("new content", _htm);
      return { __html: _htm };
    }

    const onChange = (value) => {
      //let cd = new DataTransfer()
      //console.log("markdown",value.toString('markdown'));
      //console.log("isdirty","onchange",isdirty);

      setValue(value)
      setIsdirty(true)
    }

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
      ]
    };

    let livefullmode = (editMode && fullmode) ? true : false;
    let notlivefullmode = (!editMode && !fullmode) ? true : false;

    useEffect(() => {
      console.log(outsideclickcounter);
      // setTitleEditEnabled(false);

    }, [outsideclickcounter]);

    console.log("livefullmode", localmode, livefullmode, title, isTitleEditEnabled);

    const customControls = [() => <button onClick={() => {
      fileInput.current.click();
    }}><FileImageOutlined /></button>];

    return (
      <div
        className={className}
        style={{ backgroundColor: cardColor, opacity: 1 }}

      >
        {livefullmode && (
          <div
            className="content-action-wrapper"
            style={{ backgroundColor: headerColor }}
          >
            <div className="btn-group">
              <div className="color-action">
                <BgColorsOutlined style={{ color: actionBtnColor }} />
                <div className="color-picker-wrapper">
                  <ChromePicker color={cardColor} onChange={(color) => { handleColorChange(color.hex); }} />
                  {/* <ColorPicker handleSetColor={handleColorChange} /> */}
                </div>
              </div>
              <div className="image-ation">
                <FileImageOutlined style={{ color: actionBtnColor }} />
                <input
                  type="file"
                  ref={fileInput}
                  className="image-uploader"
                  onChange={handleUpload}
                  accept="image/*"
                ></input>
              </div>
              <div className="archive-action">
                <DeleteOutlined
                  style={{ color: actionBtnColor }}
                  onClick={(evt) => {
                    handleArchive(cardKey);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className="content-header"
          style={{ backgroundColor: headerColor }}
        >
          {((!livefullmode && !isTitleEditEnabled) || (!localmode && isTitleEditEnabled)) && (
            <div onClick={ontitleclick} style={{ width: "100%", textAlign: "left" }}>
              <Tooltip title={cardTitle}>
                <span className="title-text">{cardTitle}</span>
              </Tooltip>
            </div>
          )}
          {(livefullmode || (isTitleEditEnabled && localmode)) && (
            <input
              className="title-input"
              value={cardTitle}
              onChange={handleTitleChange}
              autoFocus
            ></input>
          )}
          {livefullmode && invisible && (
            <div style={{ display: "flex", width: "50px", justifyContent: "space-around" }}>
              {/* <div
                className="card-action-container"
                onClick={() => handleItemUpdate('invisible', false, cardKey)}
              >
                <EyeInvisibleOutlined />
              </div> */}

              <div
                className="card-action-container"
                onClick={() => setFullmode(false)}
              >
                <SaveOutlined />
              </div>
            </div>
          )}
          {livefullmode && !invisible && (
            <div style={{ display: "flex", width: "50px", justifyContent: "space-around" }}>
              {/* <div
                className="card-action-container"
                onClick={() => handleItemUpdate('invisible', true, cardKey)}
              >
                <EyeOutlined />
              </div> */}
              <div
                className="card-action-container"
                onClick={() => setFullmode(false)}
              >
                <CloseOutlined style={{ color: "#fff" }} />
              </div>
            </div>
          )}
        </div>
        <div
          className={
            livefullmode ? 'card-content-wrapper' : 'card-content-wrapper edit-mode'
          }

          onClick={() => setFullmode(true)}
        >
          {cardImage && (
            <div className="item-image-wrapper">
              <img className="item-image" src={cardImage} />
            </div>
          )}
          {livefullmode && (
            <div className="content-wrapper">
              <RichTextEditor
                value={value}
                onChange={onChange}
                toolbarConfig={toolbarConfig}
                ref={rtEditor}
                {...{ customControls }}
              />


            </div>
          )}
          {!livefullmode && (
            <div className="content-wrapper" onClick={() => { setLocalmode(true); if (cardKey) { handleSelect(cardKey); } setFullmode(true); }} >
              <div style={{ height: "100%", textAlign: 'left' }} dangerouslySetInnerHTML={createMarkup()} />
            </div>
          )}
          {emptyContent && !livefullmode && (
            <div className="empty-content-wrapper">
              <p>Start typing...</p>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default DesignCardUpdate;
