import React, { Component } from 'react';
import FlexLayout, { Actions } from 'flexlayout-react';
import { layoutOption } from './layout';
import ComposePanel from '../containers/compose-panel';
import LibraryPanel from '../containers/library-panel';
import DesignPanel from '../containers/design-panel';
import 'flexlayout-react/style/light.css';
import MainMenu from '../containers/main-menu';
import SettingPanel from '../containers/setting-panel';
import EmptyPanel from '../containers/empty-panel';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = { model: FlexLayout.Model.fromJson(layoutOption) };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.leftMenu !== this.props.leftMenu) {
      this.handleChangeLayout('border_left', {
        selected: this.props.leftMenu? 0: -1
      })
    }
    if(prevProps.rightMenu !== this.props.rightMenu) {
      const { innerWidth: width, innerHeight: height } = window;
      this.handleChangeLayout('border_right', {
        selected: this.props.rightMenu? 0: -1,
        size: width/2
      })
    }
  }

  factory = (node) => {
    let component = node.getComponent();

    if (component === 'compose-panel') {
      return <ComposePanel currentproject={this.props.currentproject} setCurrentproject={this.props.setCurrentproject} handleChangeLayout={this.handleChangeLayout} />;
    }

    if (component === 'design-panel') {
        return <DesignPanel handleChangeLayout={this.handleChangeLayout} leftMenu={this.props.leftMenu} rightMenu={this.props.rightMenu} />;
    }

    if (component === 'project-menu') {
      return <MainMenu setCurrentproject={this.props.setCurrentproject} handleChangeLayout={this.handleChangeLayout} currentproject={this.props.currentproject}/>;
    }

    if (component === 'setting-menu') {
      return <SettingPanel />;
    }

    if(component === 'empty-panel') {
      return <EmptyPanel handleChangeLayout={this.handleChangeLayout} />
    }
  };

  handleChangeLayout = (nodeId, attr) => {
    const { model } = this.state;
    model.doAction(
      Actions.updateNodeAttributes(nodeId, attr),
    );
  };

  render() {
    return (
      <FlexLayout.Layout model={this.state.model} factory={this.factory} />
    );
  }
}

export default Layout;
