import React, { useState,useEffect } from 'react';
import './style.scss';
import { Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export default function FilterBarSearch(props) {
  const [formVal, setFormVal] = useState(props.filter);

  useEffect(()=>{
    setFormVal({search:''});
  },[props.chipIndex]);

  function handleChange(key, value) {
    const updatedFormVal = {
      ...formVal,
      [key]: value,
    };
    setFormVal(updatedFormVal);

    const { updateFilter } = props;
    updateFilter(updatedFormVal);
  }

  return (
    <Input
          value={formVal.search}
          onChange={(evt) => handleChange('search', evt.target.value)}
        />
  );
}
