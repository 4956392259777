export const colorOption = [
  {
    color: '#8fc8ca',
  },
  {
    color: '#cccaa3',
  },
  {
    color: '#8b6ec0',
  },
  {
    color: '#e6a05b',
  },
  {
    color: '#da5547',
  },
  {
    color: '#ffffff',
  },
];
