import React, { useState, useRef, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import ColorPicker from '../color-picker';
import './style.scss';
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  CheckOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';

const VIEW_COMPOSE = 'VIEW_COMPOSE';
const VIEW_LIBRARY = 'VIEW_LIBRARY';

const PropertyPopup = ({
  coordinate,
  title,
  property,
  handleBack,
  handleTitleChange,
  handleColorChange,
  handleRemove,
  handleEdit,
}) => {
  const { top, left } = coordinate;
  let localcolor = '#ffffff';

  const handleAction = (evt) => {
    evt.stopPropagation();
  };

  const [text, setText] = useState(title);

  const handleText = (evt) => {
    setText(evt.target.value);
    handleTitleChange(evt.target.value);
  };

  const handleColorChangeEvent = (evt) => {
    handleColorChange(evt);
    // handleBack();
    localcolor = evt;
  };

  const handleTextEnter = (evt) => {

    if (evt.key === 'Enter') {
      handleBack();
    }
  };

  const handleAdd = () => { };
  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  return (
    <div className="popup-container" onClick={handleBack}>
      <div
        className="main-content"
        style={{ top: top, left: left + 25 }}
        onClick={handleAction}
      >
        <div className="popup-title-container">
          <span className="popup-title">Information</span>
          <div className="popup-header-action">
            <CloseCircleOutlined
              className="icon icon-close"
              onClick={handleBack}
            />
          </div>
        </div>
        <input
          className="popup-title-input"
          value={text}
          onChange={handleText}
          onKeyDown={handleTextEnter}
          ref={inputElement}
        />
        <div className='colorPickerTitle'>Choose Card Background Color</div>
        <ChromePicker color={localcolor} onChange={(color) => { handleColorChangeEvent(color.hex); }} />
        {/* <ColorPicker handleSetColor={handleColorChangeEvent} /> */}
        {property === VIEW_COMPOSE && (
          <div className="footer-container">
            <Tooltip placement="topLeft" title="Remove Chip">
              <DeleteOutlined
                className="icon icon-remove"
                onClick={handleRemove}
              />
            </Tooltip>
            <Tooltip placement="topLeft" title="Save Chip">
              <CheckOutlined className="icon icon-save" onClick={handleBack} />
            </Tooltip>
          </div>
        )}
        {property === VIEW_LIBRARY && (
          <div className="footer-container">
            <Tooltip placement="topLeft" title="Remove Library">
              <DeleteOutlined
                className="icon icon-remove"
                onClick={handleRemove}
              />
            </Tooltip>
            <Tooltip placement="topLeft" title="Add to Compose">
              <PlusCircleOutlined
                className="icon icon-add"
                onClick={handleAdd}
              />
            </Tooltip>
            <Tooltip placement="topLeft" title="Edit Library">
              <EditOutlined className="icon icon-edit" onClick={handleEdit} />
            </Tooltip>
          </div>
        )}

        <div className="popup-arrow"></div>
      </div>
    </div>
  );
};

export default PropertyPopup;

//
