import React, { useState, useEffect } from 'react';
import Layout from '../../layout';
import { useAppState } from '../../context/app.context';
import './style.scss';
import EmptyPanel from '../empty-panel';

function MainApp(props) {
  const { theme, updateSettings, settings } = useAppState();
  const { startFlag, setStartFlag, setLeftMenu, setCurrentproject } = props;
  console.log("props",props);

  useEffect(() => {
    var pathname = window.location.pathname.replace("/","").replace("/","");

    if(pathname){
      setStartFlag(true);
      setLeftMenu(true);
      setCurrentproject(pathname);
      //settings.selectedProjectId = pathname;
      //settings.selectedChipId = "";
      //updateSettings({...settings});
    }
    
  },[]);

  return (
    <div className={`main-app-container theme-${theme}`}>
      {startFlag && <Layout {...props} />}
      {!startFlag && <EmptyPanel setStartFlag={setStartFlag} />}
    </div>
  );
}

export default MainApp;
